var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('v-app-bar',{style:(_vm.userSettings != undefined && _vm.userSettings.toolbarTransparent
        ? 'background: transparent;box-shadow:none;'
        : ''),attrs:{"app":"","clipped":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),(_vm.status.loggedIn)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('search'),(_vm.permitted('Dashboard.View'))?_c('DashboardTreeSelect'):_vm._e(),(_vm.permitted('Settings.GetSettings', ''))?_c('Notification'):_vm._e()],1):_vm._e()],1),_c('v-navigation-drawer',{class:{
      darkSientific: _vm.darkTheme && _vm.userSettings.theme == 'Scientific',
      lightSientific: !_vm.darkTheme && _vm.userSettings.theme == 'Scientific',
      lightDrawer: !_vm.darkTheme && _vm.userSettings.theme != 'Scientific',
      darkDrawer: _vm.darkTheme && _vm.userSettings.theme != 'Scientific',
      primary: _vm.userSettings.theme != 'Scientific',
      'white--text': !_vm.darkTheme,
      'dark--text': _vm.darkTheme,
    },attrs:{"app":"","temporary":!_vm.userSettings.stickySideMenu},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-img',{attrs:{"height":"75px","width":"200px","src":_vm.whiteLabel
                ? 'so-white-label.png'
                : _vm.darkTheme
                ? '/sensor-online-dark.svg'
                : '/sensor-online.svg'}})],1)],1),_c('v-divider'),_vm._l((_vm.viewableLinks),function(link){return _c('v-list-item',{key:link.text,attrs:{"router":"","to":link.route}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(link.text)+" ")])],1)],1)}),_c('v-divider'),_c('v-list-item',{attrs:{"href":this.SO_API_BASE_URL,"target":"_blank"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.apiLink.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.apiLink.text)+" ")])],1)],1),(_vm.showShopLink == true)?_c('v-list-item',{attrs:{"href":_vm.sensorShopLink.externalRoute,"target":"_blank"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.sensorShopLink.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.sensorShopLink.text)+" ")])],1)],1):_vm._e(),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-switch',{attrs:{"label":_vm.$t('common.darkTheme')},model:{value:(_vm.darkTheme),callback:function ($$v) {_vm.darkTheme=$$v},expression:"darkTheme"}})],1)],1),_c('v-divider'),(_vm.isSuperAdmin() == true)?_c('v-container',{staticClass:"mg-5"},[_c('v-subheader',{staticClass:"justify-center",attrs:{"elevation":"100"}},[_c('v-label',[_vm._v(" "+_vm._s(_vm.$t("common.switchCompany"))+" ")])],1),_c('v-select',{staticStyle:{"margin-bottom":"5rem"},attrs:{"items":_vm.companies,"placeholder":_vm.$t('routes.company'),"item-text":"name","item-value":"companyId","outlined":"","hide-details":""},on:{"change":_vm.submit},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }